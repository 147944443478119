import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowDownCircle, Mail, Calendar, Check } from 'lucide-react';
import ScatteredPurpleBackground from '../ScatteredPurpleBackground';
import AppointmentModal from '../components/utility/AppointmentModal';
import OnlineModal from '../components/utility/OnlineAppointment';
import SEOMetaTags from '../components/utility/SEOMetaTags';
import { Helmet } from 'react-helmet';

// Extend the SEOMetaTags component to include laparoscopic page type
declare module '../components/utility/SEOMetaTags' {
  interface SEOMetaTagsProps {
    page: 'home' | 'about' | 'services' | 'blogs' | 'contact' | 'laparoscopic';
  }
}

// Create a custom SEOMetaTags component for the laparoscopic page
const LaparoscopicSEOTags: React.FC = () => {
  const pageTitle = "Dr. Kanika Jain - Expert Laparoscopic & Robotic Surgeon in Delhi NCR";
  const pageDescription = "Dr. Kanika Jain: Leading laparoscopic and robotic surgeon in Delhi NCR with 28+ years of experience. Specializing in minimally invasive gynecological surgeries, including hysterectomy, myomectomy, and endometriosis treatment with advanced surgical techniques.";
  const pageKeywords = "Dr. Kanika Jain, laparoscopic surgeon Delhi, robotic surgeon, gynecological surgery, minimally invasive surgery, hysterectomy, myomectomy, endometriosis surgery, fibroid removal, Sir Ganga Ram Hospital, gynecology surgeon Delhi NCR";

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={pageKeywords} />
      <meta name="author" content="Dr. Kanika Jain" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.drkanikajain.com/laparoscopic" />
      <meta property="og:image" content="https://www.drkanikajain.com/dr-kanika-jain-surgeon.jpg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content="https://www.drkanikajain.com/dr-kanika-jain-surgeon.jpg" />
      <link rel="canonical" href="https://www.drkanikajain.com/laparoscopic" />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Physician",
            "name": "Dr. Kanika Jain",
            "image": "https://www.drkanikajain.com/dr-kanika-jain-surgeon.jpg",
            "description": "${pageDescription}",
            "medicalSpecialty": [
              "Gynecologic Surgery",
              "Laparoscopic Surgery",
              "Robotic Surgery"
            ],
            "knowsAbout": [
              "Minimally Invasive Surgery",
              "Robotic Surgery",
              "Hysterectomy",
              "Myomectomy",
              "Endometriosis",
              "Fibroid Removal"
            ],
            "hasCredential": [
              "MBBS",
              "DGO",
              "DNB",
              "FICOG",
              "FICMCH"
            ],
            "memberOf": [
              {
                "@type": "Organization",
                "name": "Sir Ganga Ram Hospital"
              }
            ],
            "url": "https://www.drkanikajain.com/laparoscopic",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Delhi",
              "addressRegion": "NCR",
              "addressCountry": "India"
            }
          }
        `}
      </script>
    </Helmet>
  );
};

// Animation variants for text animation
const wordVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

// Animated word component
export const AnimatedWord: React.FC<{ word: string }> = ({ word }) => {
  return (
    <motion.span
      className="mr-1 inline-block font-fraunces-slab"
      variants={wordVariants}
      transition={{ duration: 0.5 }}
    >
      {word}
    </motion.span>
  );
};

// Benefit card component
interface BenefitCardProps {
  title: string;
  description: string;
  icon?: React.ReactNode;
  delay?: number;
}

const BenefitCard: React.FC<BenefitCardProps> = ({ title, description, icon, delay = 0 }) => {
  return (
    <motion.div
      className="bg-white p-6 rounded-lg shadow-lg mb-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, delay }}
    >
      <div className="flex items-start mb-4">
        {icon || <Check className="text-purple-600 w-6 h-6 mr-3 mt-1" />}
        <h3 className="text-xl font-semibold text-purple-700">{title}</h3>
      </div>
      <p className="text-gray-700 font-playfair-display-real">{description}</p>
    </motion.div>
  );
};

// Procedure card component
interface ProcedureCardProps {
  title: string;
  description: string;
  image: string;
  delay?: number;
}

// Testimonial component
interface TestimonialProps {
  quote: string;
  author: string;
  delay?: number;
}

const Testimonial: React.FC<TestimonialProps> = ({ quote, author, delay = 0 }) => {
  return (
    <motion.div
      className="bg-purple-50 p-6 rounded-lg shadow-md mb-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, delay }}
    >
      <div className="text-purple-600 mb-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className="opacity-50">
          <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" />
        </svg>
      </div>
      <p className="text-gray-700 font-playfair-display-real italic mb-4">{quote}</p>
      <p className="text-gray-900 font-semibold">{author}</p>
    </motion.div>
  );
};

const LaparoscopicPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openModal2 = () => setIsModalOpen2(true);
  const closeModal2 = () => setIsModalOpen2(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <LaparoscopicSEOTags />
      <ScatteredPurpleBackground>
        <div className="flex flex-col md:flex-row min-h-screen relative">
          {/* Background Image (visible on mobile) */}
          <div className="absolute inset-0 md:hidden">
            <div
              className="w-full h-full bg-cover bg-center"
              style={{
                backgroundImage: "url('/images/pg.jpg')",
                filter: "brightness(0.7)"
              }}
            />
          </div>

          <motion.section
            className="w-full md:w-1/2 flex items-center justify-center px-6 py-12 mt-20 md:mt-0 md:py-24 relative z-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6 }}
          >
            <div className="max-w-xl w-full">
              <h1 className="font-fraunces-slab text-3xl md:text-4xl lg:text-5xl leading-tight mb-6 text-white md:text-zinc-900 ml-5 lg:ml-16">
                <span className="block md:inline">Dr. Kanika Jain</span>

                <motion.span
                  initial="hidden"
                  animate="visible"
                  variants={{
                    hidden: {},
                    visible: {
                      transition: {
                        staggerChildren: 0.8
                      }
                    }
                  }}
                  className="block"
                >
                  <AnimatedWord word="Advanced" />
                  <AnimatedWord word="Laparoscopic" />
                </motion.span>
                <motion.span
                  initial="hidden"
                  animate="visible"
                  variants={{
                    hidden: {},
                    visible: {
                      transition: {
                        staggerChildren: 0.8
                      }
                    }
                  }}
                  className="block"
                >
                  <AnimatedWord word="&" />
                  <AnimatedWord word="Robotic" />
                </motion.span>
                <motion.span
                  initial="hidden"
                  animate="visible"
                  variants={{
                    hidden: {},
                    visible: {
                      transition: {
                        staggerChildren: 0.8
                      }
                    }
                  }}
                  className="block"
                >
                  <AnimatedWord word="Surgeon" />
                </motion.span>
              </h1>

              <motion.p
                className="text-lg md:text-xl text-white md:text-gray-700 mb-8 ml-5 lg:ml-16 font-playfair-display-real"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
              >
                Internationally trained specialist with 28+ years of experience in minimally invasive gynecological surgeries at Sir Ganga Ram Hospital.
              </motion.p>

              <motion.div
                className="mt-8 flex flex-wrap items-center space-x-2 md:space-x-4 ml-5 lg:ml-16"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.3 }}
              >
                <button
                  className="inline-flex items-center px-4 py-2 md:px-6 md:py-3 text-md md:text-xl font-semibold text-white bg-purple-600 rounded-lg mb-2 md:mb-0"
                  onClick={openModal}
                >
                  Schedule Consultation
                  <Calendar className="ml-2 w-4 h-4 md:w-6 md:h-6" />
                </button>
                <a href="mailto:dr.kanika@gmail.com" className="inline-flex items-center justify-center w-10 h-10 md:w-12 md:h-12 bg-purple-600 rounded-full mb-2 md:mb-0">
                  <Mail className="w-5 h-5 md:w-6 md:h-6 text-white" />
                </a>
                <a href="#" onClick={openModal2} className="inline-flex items-center justify-center w-10 h-10 md:w-12 md:h-12 bg-green-500 rounded-full mb-2 md:mb-0">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 md:w-7 md:h-7 fill-current text-white">
                    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.890-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
                  </svg>
                </a>
                <OnlineModal isOpen2={isModalOpen2} onClose2={closeModal2} />
              </motion.div>
            </div>
          </motion.section>

          {/* Image Section (visible on desktop) */}
          <motion.section
            className="hidden md:block w-1/2 h-screen relative"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.9, delay: 0.3 }}
          >
            <img
              src="/images/pg.jpg"
              alt="Dr. Kanika Jain performing laparoscopic surgery"
              className="w-full h-full object-cover"
            />
          </motion.section>
        </div>

        <AppointmentModal isOpen={isModalOpen} onClose={closeModal} />
      </ScatteredPurpleBackground>

      {/* Expertise Section */}
      <div className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold text-purple-800 mb-4">Advanced Surgical Expertise</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto font-playfair-display-real">
              Dr. Kanika Jain brings cutting-edge laparoscopic and robotic surgical techniques to women's healthcare in Delhi NCR.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <BenefitCard
              title="Internationally Certified"
              description="Certified robotic surgeon from EEC Paris (2014) with fellowship in endoscopic surgery (2008) and gynae robotic surgery (2012)."
              delay={0.1}
            />
            <BenefitCard
              title="28+ Years Experience"
              description="Extensive experience in performing complex gynecological surgeries using minimally invasive techniques at premier hospitals."
              delay={0.2}
            />
            <BenefitCard
              title="Cutting-Edge Technology"
              description="Utilizes the latest surgical technology and equipment to ensure optimal outcomes and patient safety."
              delay={0.3}
            />
            <BenefitCard
              title="Recognized Expertise"
              description="Recipient of the APJ Abdul Kalam award for proficiency in gynecology and numerous other national and international awards."
              delay={0.4}
            />
            <BenefitCard
              title="Research & Innovation"
              description="Actively involved in research and development of new surgical techniques for enhanced patient outcomes."
              delay={0.5}
            />
            <BenefitCard
              title="Personalized Care"
              description="Tailors surgical approaches to each patient's specific needs, focusing on both medical outcomes and quality of life."
              delay={0.6}
            />
          </div>
        </div>
      </div>

      {/* Laparoscopic & Robotic Surgery Benefits */}
      <div className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold text-purple-800 mb-4">Benefits of Minimally Invasive Surgery</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto font-playfair-display-real">
              Dr. Kanika Jain specializes in laparoscopic and robotic surgeries that offer significant advantages over traditional open surgeries.
            </p>
          </div>

          {/* Advanced 4mm Technology Section */}
          <div className="py-16 bg-gradient-to-r from-purple-50 to-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="text-center mb-12">
                <h2 className="text-3xl md:text-4xl font-bold text-purple-800 mb-4">Advanced 4mm Telescope Surgery</h2>
                <p className="text-xl text-gray-600 max-w-3xl mx-auto font-playfair-display-real">
                  Dr. Kanika Jain utilizes state-of-the-art 4mm telescope technology for truly minimally invasive procedures.
                </p>
              </div>

              <div className="bg-white p-8 rounded-lg shadow-xl border-l-4 border-purple-600 mb-12">
                <h3 className="text-2xl font-semibold text-purple-800 mb-4">Micro-Incision Laparoscopic Surgery</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div>
                    <ul className="space-y-4">
                      <li className="flex items-start">
                        <Check className="text-purple-600 w-6 h-6 mr-3 mt-1 flex-shrink-0" />
                        <p className="font-playfair-display-real font-medium text-gray-800">Ultra-small 4mm telescope technology for enhanced visualization</p>
                      </li>
                      <li className="flex items-start">
                        <Check className="text-purple-600 w-6 h-6 mr-3 mt-1 flex-shrink-0" />
                        <p className="font-playfair-display-real font-medium text-gray-800">Tiny 5-6mm incisions compared to standard 10mm in traditional laparoscopy</p>
                      </li>
                      <li className="flex items-start">
                        <Check className="text-purple-600 w-6 h-6 mr-3 mt-1 flex-shrink-0" />
                        <p className="font-playfair-display-real font-medium text-gray-800"><span className="font-bold text-purple-700">No stitches required</span> - incisions heal naturally with minimal scarring</p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul className="space-y-4">
                      <li className="flex items-start">
                        <Check className="text-purple-600 w-6 h-6 mr-3 mt-1 flex-shrink-0" />
                        <p className="font-playfair-display-real font-medium text-gray-800">Significantly reduced pain compared to conventional techniques</p>
                      </li>
                      <li className="flex items-start">
                        <Check className="text-purple-600 w-6 h-6 mr-3 mt-1 flex-shrink-0" />
                        <p className="font-playfair-display-real font-medium text-gray-800">Faster recovery and return to normal activities</p>
                      </li>
                      <li className="flex items-start">
                        <Check className="text-purple-600 w-6 h-6 mr-3 mt-1 flex-shrink-0" />
                        <p className="font-playfair-display-real font-medium text-gray-800">Reduced risk of wound complications and infections</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-white p-8 rounded-lg shadow-lg border-t-4 border-purple-500">
                  <h3 className="text-2xl font-semibold text-purple-700 mb-4">Multi-port Laparoscopic Approach</h3>
                  <p className="text-gray-700 mb-4 font-playfair-display-real">
                    Dr. Kanika Jain's precision technique uses only 4-5 strategically placed small ports (5-6mm each) providing complete access for complex gynecological procedures.
                  </p>
                  <ul className="space-y-3">
                    <li className="flex items-start">
                      <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                      <p className="font-playfair-display-real">Customized port placement based on patient anatomy</p>
                    </li>
                    <li className="flex items-start">
                      <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                      <p className="font-playfair-display-real">Optimal visualization and instrument access</p>
                    </li>
                    <li className="flex items-start">
                      <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                      <p className="font-playfair-display-real">Virtually invisible scars within months</p>
                    </li>
                  </ul>
                </div>

                <div className="bg-white p-8 rounded-lg shadow-lg border-t-4 border-purple-500">
                  <h3 className="text-2xl font-semibold text-purple-700 mb-4">Single Incision Laparoscopic Hysterectomy</h3>
                  <p className="text-gray-700 mb-4 font-playfair-display-real">
                    Dr. Kanika is one of the few surgeons in Delhi NCR offering advanced single-incision techniques using 4mm technology for selected cases.
                  </p>
                  <ul className="space-y-3">
                    <li className="flex items-start">
                      <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                      <p className="font-playfair-display-real">Single 2cm umbilical incision with multiple 4-5mm ports</p>
                    </li>
                    <li className="flex items-start">
                      <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                      <p className="font-playfair-display-real">Completely hidden scar in the navel</p>
                    </li>
                    <li className="flex items-start">
                      <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                      <p className="font-playfair-display-real">Exceptional cosmetic results with all medical benefits</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div
              className="bg-white p-8 rounded-lg shadow-lg"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
            >
              <h3 className="text-2xl font-semibold text-purple-700 mb-6">Laparoscopic Surgery</h3>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                  <p className="font-playfair-display-real">Smaller incisions resulting in minimal scarring</p>
                </li>
                <li className="flex items-start">
                  <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                  <p className="font-playfair-display-real">Reduced post-operative pain and discomfort</p>
                </li>
                <li className="flex items-start">
                  <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                  <p className="font-playfair-display-real">Shorter hospital stays, often same-day discharge</p>
                </li>
                <li className="flex items-start">
                  <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                  <p className="font-playfair-display-real">Faster recovery and return to normal activities</p>
                </li>
                <li className="flex items-start">
                  <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                  <p className="font-playfair-display-real">Lower risk of infection and blood loss</p>
                </li>
                <li className="flex items-start">
                  <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                  <p className="font-playfair-display-real">Better visualization of pelvic structures</p>
                </li>
              </ul>
            </motion.div>

            <motion.div
              className="bg-white p-8 rounded-lg shadow-lg"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <h3 className="text-2xl font-semibold text-purple-700 mb-6">Robotic Surgery</h3>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                  <p className="font-playfair-display-real">Enhanced 3D visualization with magnification</p>
                </li>
                <li className="flex items-start">
                  <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                  <p className="font-playfair-display-real">Greater surgical precision and dexterity</p>
                </li>
                <li className="flex items-start">
                  <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                  <p className="font-playfair-display-real">Improved access to difficult-to-reach areas</p>
                </li>
                <li className="flex items-start">
                  <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                  <p className="font-playfair-display-real">Reduced surgeon fatigue allowing longer procedures</p>
                </li>
                <li className="flex items-start">
                  <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                  <p className="font-playfair-display-real">More precise suturing capabilities</p>
                </li>
                <li className="flex items-start">
                  <Check className="text-green-500 w-5 h-5 mr-3 mt-1 flex-shrink-0" />
                  <p className="font-playfair-display-real">Ideal for complex cases requiring fine motor skills</p>
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>



      {/* Testimonials */}
      <div className="py-16 bg-purple-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold text-purple-800 mb-4">Patient Success Stories</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto font-playfair-display-real">
              Read about the experiences of patients who have undergone successful surgeries with Dr. Kanika Jain.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <Testimonial
              quote="After struggling with fibroids for years, Dr. Kanika performed a robotic myomectomy that changed my life. The recovery was so much faster than I expected, and I was back to work within a week. Her expertise and compassionate care were truly remarkable."
              author="Priya S., Delhi"
              delay={0.1}
            />
            <Testimonial
              quote="I had severe endometriosis that multiple doctors couldn't properly treat. Dr. Kanika's laparoscopic approach was game-changing. For the first time in 10 years, I'm pain-free. Her surgical precision and attention to detail are unmatched."
              author="Meera R., Gurgaon"
              delay={0.2}
            />
            <Testimonial
              quote="The thought of having a hysterectomy terrified me, but Dr. Kanika made the entire process so much easier than I imagined. The robotic surgery she performed had minimal scarring and I recovered in just days instead of weeks."
              author="Anjali P., Noida"
              delay={0.3}
            />
            <Testimonial
              quote="After two failed surgeries elsewhere, Dr. Kanika successfully removed my complex ovarian cysts while preserving my fertility. Her laparoscopic expertise and follow-up care were exceptional. I'm forever grateful."
              author="Sunita K., Delhi NCR"
              delay={0.4}
            />
          </div>
        </div>
      </div>

    </>
  )
}

export default LaparoscopicPage;